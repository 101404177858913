<template>
  <div class="commentItem">
    <!-- 导航栏 -->
    <van-nav-bar :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('discover.commentList') }}</span>
      </template>
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"
        :color="!$isLinkGame ? '#ffd400' : '#fff'"></i>
    </van-nav-bar>
    <!-- 晒单项 -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :success-text="refreshSuccessText"
      :loosing-text="$t('home.releaseToRefresh')" :loading-text="$t('common.loading')"
      :pulling-text="$t('common.dropDownRefresh')">
      <van-list :finished="finished" :finished-text="$t('common.noFurtherInformation')" @load="onLoad"
        :loading-text="$t('common.loading')">
        <van-cell>
          <div slot="default" class="list-item">
            <img class="avatar" :src="item.userimg" alt="" />
            <div class="list-content">
              <span class="username">{{ item.username }}</span>
              <span class="text">{{ item.content }}</span>
              <div class="img" v-for="(picture, i) in item.pictures" :key="i + '1'">
                <img :src="picture.url" alt="" @click="clickimg" />
                <van-image-preview v-model="show" :images="images" @change="onChange">
                  <template v-slot:index>{{ i / images.length }}</template>
                </van-image-preview>
              </div>
              <div class="like-box" @click="clickLike(item.id)">
                <i class="iconfont icon-dianzan" v-if="item.is_good === 0"></i>
                <i v-else class="iconfont icon-dianzan1 redLike"></i>
                <span class="likeNum">{{ item.good }}</span>
              </div>
            </div>
          </div>
          <div class="comments-main">
            <div class="comments-box" v-for="(commentItem, index) in commentList" :key="index">
              <div class="userComment">
                <span class="userName">{{ commentItem.username }}</span>
                <span class="commentsText">{{
                  (commentItem.indate * 1000) | formatTime('YYYY-MM-DD HH:mm')
                }}</span>
              </div>
              <p class="commentsText">{{ commentItem.content }}</p>
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <div class="review" @click="GoComment(item.id)">
      <span>{{ $t('discover.comment') }}</span>
      <van-button slot="right" type="warning" :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">{{
        $t('discover.announce') }}</van-button>
    </div>
  </div>
</template>

<script>
import { clickGood, CommentItem } from '@/api/comments.js'
import { unique } from '@/utils/unique.js'
export default {
  name: '',
  components: {},
  data() {
    return {
      item: this.$route.query.item.id
        ? this.$route.query.item
        : JSON.parse(window.localStorage.commentItem),
      num: Number,
      pages: '1',
      commentList: [],
      loading: false,
      finished: false,
      refreshing: false,
      refreshSuccessText: '', // 下拉刷新成功时显示的文字
      show: false,
      images: [],
      index: 0
    }
  },
  mounted() { },
  created() {
    this.getCommentItem()
  },
  methods: {
    // 点击当前点赞图标时触发的函数
    async clickLike(id) {
      const {
        data: { ret, msg, data }
      } = await clickGood({ id: id })
      if (ret === 1) {
        this.item.good = data.good
        this.item.is_good = data.is_good
      } else {
        this.$toast(msg)
      }
    },
    // 点击去评论时触发的函数
    GoComment(id) {
      this.$router.push({
        path: '/discoverReview',
        query: { isToken: true, id: id }
      })
    },
    // 获取当前点击的用户的晒单内容
    async getCommentItem() {
      const {
        data: { ret, msg, data }
      } = await CommentItem({
        id: this.item.id,
        page: this.pages
      })
      if (ret === 1) {
        this.commentList.push(...data.lists)
        this.loading = false
        if (this.commentList.length === data.total) {
          this.finished = true
        }
      } else {
        this.$toast(msg)
      }
    },
    // 下拉刷新时触发的函数
    async onRefresh() {
      const {
        data: { ret, msg, data }
      } = await CommentItem({
        id: this.item.id,
        page: this.pages
      })
      try {
        this.commentList.unshift(...data.lists)
        this.commentList = unique(this.commentList)

        // 3、下拉结束之后就将下拉状态关闭
        this.refreshing = false
        // 4、下拉成功时显示提示文字
        this.refreshSuccessText = this.$t('common.successfulRenewal')
      } catch (error) {
        // 如果下拉失败，则关闭下拉状态，显示下拉失败的消息
        this.refreshing = false
        this.refreshSuccessText = this.$t('common.refreshFailed')
      }
    },
    onLoad() {
      //   this.pages++;
      //   this.getcommentList();
    },
    // 点击预览图片
    clickimg() {
      this.show = true
      this.item.pictures.forEach((e) => {
        this.images.push(e.url)
      })
    },
    onChange(index) {
      this.index = index
    }
  }
}
</script>

<style lang="less" scoped>
.commentItem {
  .van-nav-bar {
    width: 100%;
    height: 100px;
    position: fixed;
  }

  /deep/ .van-nav-bar__content {
    // background: #ff0000;
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }

    .van-nav-bar__title {
      // color: #fff;
    }
  }

  .van-list {
    margin: 100px 0;

    .van-cell {
      padding: 0;
      margin-top: 15px;

      .list-item {
        padding: 0 20px;
        display: flex;
        justify-content: flex-start;

        .avatar {
          flex: 0.5;
          margin-top: 15px;
          width: 100px;
          height: 100px;
          margin-right: 15px;
        }

        .list-content {
          display: flex;
          flex: 2.5;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;

          .img {
            margin-top: 10px 0 15px 0;
            width: 300px;
            height: 300px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .like-box {
            width: 100%;
            height: 35px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 35px;
            color: #999;

            .iconfont {
              font-size: 35px;
            }

            .redLike {
              color: #fd7bb8;
            }
          }

          .comments {
            color: #aaa;
          }
        }
      }

      .comments-main {
        background: #f4f8f9 !important;
        width: 100%;

        .comments-box {
          box-sizing: border-box;
          padding: 0 20px 0 150px;
          font-size: 26px;
          height: 100px;
          width: 100%;
          border-bottom: 1px solid #eee;

          .userComment {
            display: flex;
            width: 100%;
            justify-content: space-between;
          }

          .commentsText {
            color: #aaa;
          }
        }
      }
    }
  }

  .review {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    position: fixed;
    padding: 0 25px;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;
    background: #ecf0f0;
    color: #999;

    .van-button {
      width: 150px;
      height: 60px;
      background-color: #ff0000;
      border: 0;
      color: #fff;
    }
  }
}
</style>
